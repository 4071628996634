<template>
  <form
    v-click-outside="onClickOutside"
    class="homeSearch"
    :class="{
    open: showResults
  }">
    <input id="vue-search-box-input"
           type="text"
           autocomplete="off"
           aria-autocomplete="list"
           :value="search"
           placeholder="Search by city or address"
           :class="stateClass"
           v-on:focusin="showResults = true"
           v-on:keydown.enter.prevent="searchNowFromButton"
           ref="searchWidgetInput"
           @keydown.tab.prevent="focusNext"
           @keydown.down.prevent="focusNext"
           @keydown.right.prevent="focusNext"
           @input="updateSearch"
           @paste="updateSearch"
    >
    <label class="screen-reader-text" for="vue-search-box-input">Search by city or address</label>
    <!-- todo(SCD) in search mode showResultsMode goToRegionMode if perfect match to a region name -->
    <svg v-if="search !== ''"
         v-on:click="clearSearch"
         role="presentation" class="clearInput">
      <use :xlink:href="iconPath('close')"></use>
    </svg>
    <svg v-on:click="searchNowFromButton" class="searchIcon" :class="stateClass" xmlns="http://www.w3.org/2000/svg"
         width="30" height="30"
         viewBox="0 0 30 30">
      <path class="solid"
            d="M26.438 29.296H3.56a.953.953 0 01-.953-.953V8.097a.953.953 0 01.485-.83L14.529.826a.952.952 0 01.936 0l11.436 6.441a.952.952 0 01.486.83v20.246a.953.953 0 01-1.906 0l.953-.953a.953.953 0 010 1.906z"/>
      <path class="outline"
            d="M17 30H3a1 1 0 01-1-1V7.758a1 1 0 01.509-.871l12-6.758a1 1 0 01.982 0l12 6.758a1 1 0 01.51.871V19a1 1 0 01-2 0V8.343l-11-6.2-11 6.2V28h13a1 1 0 010 2z"/>
      <path class="mag"
            d="M27.707 28.293l-7.563-7.563A7.021 7.021 0 1018.586 22l7.707 7.707a1 1 0 001.414-1.414zM10 16a5 5 0 115 5 5.006 5.006 0 01-5-5z"/>
    </svg>
    <template v-if="showResults && emptyResults">
      <search-widget-empty-results
        :region="defaultRegion"
        :regions="regions"
        :loading="loading"
        :show-no-results-text="showNoResultsText"
        :show-map-search="showMapSearchLink"
        ref="searchWidgetEmptyResults"
        @get-location="getLocation($event)"
        @did-click-link="didClickLink"
        @close-results="showResults = false"
      />
    </template>
    <search-widget-results
      v-else-if="showResults"
      :search="search"
      :search-results="searchResults"
      :max-results-display="maxResultsDisplay"
      :current-region="currentRegion"
      :show-cities="showResultTypes.includes('cities')"
      :show-zips="showResultTypes.includes('zip_codes')"
      :show-communities="showResultTypes.includes('communities')"
      :show-apartments="showResultTypes.includes('apartments')"
      :show-map-search="showMapSearchLink"
      :loading="loading"
      ref="searchWidgetResults"
      @get-location="getLocation($event)"
      @did-click-link="didClickLink"
      @close-results="showResults = false"
    />
  </form>
</template>

<script>
import { mountingMixin } from '@/components/mixins/mountingMixin'
import SearchWidgetResults from '@/components/search_widget/SearchWidgetResults'
import SearchWidgetEmptyResults from '@/components/search_widget/SearchWidgetResultsEmpty'
import { mapGetters, mapActions } from 'vuex'
import { debounce } from '@/utilities/timingUtilities'
import HomeModel from '@/models/HomeModel'
import {
  isEmpty, getInitialSettingRegionSlug,
  getInitialSettingCity,
  getInitialSettingRegionName, initialSettings, isNotEmpty
} from '@/utilities/helpers'
import { getRegion, searchRegions } from '@/api/regionAPI'
import groupBy from 'lodash/groupBy'
import { arrayContainsAny } from '@/utilities/arrayHelpers'
import vClickOutside from 'v-click-outside'
import { iconPath } from '@/utilities/urlHelpers'
import { slugify } from '@/utilities/stringFormatters'
// switch to using this for click away from the search box current method is a mess
// https://github.com/ndelvalle/v-click-outside
export default {
  name: 'SearchWidget',
  directives: {
    clickOutside: vClickOutside.directive
  },
  mixins: [mountingMixin],
  components: {
    SearchWidgetEmptyResults,
    SearchWidgetResults
  },
  props: {
    specialRegions: {
      type: Object,
      default: () => {
        return {}
      },
      required: false
    },
    maxResultsDisplay: {
      type: Number,
      default: 5,
      required: false
    },
    showMapSearch: {
      type: Boolean,
      default: false,
      required: false
    },
    showResultTypes: {
      type: Array,
      default: () => {
        return ['regions', 'cities', 'homes', 'communities', 'apartments', 'zip_codes']
      },
      required: false
    },
    shouldEmit: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      loading: false,
      search: '',
      showResults: false,
      showNoResultsText: false,
      searchingNow: false,
      currentCity: null,
      currentRegion: null,
      currentRegionSlug: '',
      resultsLimit: 20,
      homes: [],
      debounceSearch: null,
      debounceSearchDeep: null,
      debounceShowMap: null,
      searchResults: {},
      defaultRegion: null,
      defaultRegionSlug: '',
      initialState: null,
      showMapSearchLink: false
    }
  },
  mounted () {
    const regionSlug = getInitialSettingRegionSlug()

    const vm = this
    if (isNotEmpty(regionSlug) && this.specialRegions[regionSlug] !== 'undefined') {
      this.currentRegion = this.specialRegions[regionSlug]
      this.defaultRegion = this.specialRegions[regionSlug]
      this.setInitialState(this.search, this.searchResults)
    }
    this.currentRegionSlug = regionSlug
    this.defaultRegionSlug = this.currentRegionSlug
    this.currentCity = getInitialSettingCity()

    this.debounceSearch = debounce(function () {
      console.log('debouncedSearch', vm.search)
      vm.searchNow('debounce')
    }, 750)
    this.debounceSearchDeep = debounce(function () {
      console.log('debouncedSearchDeep', vm.search)
      vm.searchNow('debounce', true)
    }, 1500)
    this.debounceShowMap = debounce(function () {
      vm.showMapSearchLink = vm.showMapSearch
    }, 1000)

    // if (getInitialSettingCountry() !== 'CA') {
    if (getInitialSettingRegionName() !== '') {
      this.searchString(getInitialSettingRegionName())
    }
    // }
  },
  created () {
    // todo(scd) this will be replaced with getCurrentRegion from
    // server side
    // if (getInitialSettingCountry() !== 'CA') {
    this.initRegions()
      .catch(e => {
        console.error('regions', e)
        window.alert('Error loading regions')
      })
    const regionSlug = getInitialSettingRegionSlug()
    if (isNotEmpty(regionSlug)) {
      getRegion(regionSlug)
        .then(result => {
          if (result.status === 200) {
            const data = result.data
            this.currentRegion = data.data
            this.defaultRegion = this.currentRegion
            this.defaultRegionSlug = this.currentRegion.slug
            this.currentRegion.featured_homes = this.currentRegion.featured_homes.map(home => {
              return new HomeModel(home)
            })
            this.homes = this.currentRegion.featured_homes
          }
        })
    }
  },
  watch: {
    search (newSearch, oldSearch) {
      // per #h1096v bring back map search
      // if (newSearch.length >= 0) {
      //   this.showMapSearch = false
      // }
      if (this.isSpecialRegion) {
        this.showResults = true
      }
      if (newSearch.length >= 3) {
        if (!isEmpty(oldSearch) &&
          newSearch.trim().toLowerCase().includes(oldSearch.trim().toLowerCase())) {
          this.debounceSearchDeep()
        } else {
          this.debounceSearch()
          this.debounceSearchDeep()
        }
      }
    },
    searchResults (newResults) {
      this.debounceShowMap()
    },
    initialState (newState, oldState) {
      console.trace('SearchWidget.watch.initialState')
      console.log('SearchWidget.watch.initialState', newState, oldState)
    }
  },
  computed: {
    ...mapGetters({
      regions: 'regions',
      getRegion: 'region'
    }),
    initialSettings () {
      return initialSettings()
    },
    searchResultsContainSearch () {
      const search = this.search.toLowerCase()
      const results = this.searchResults

      // get result types
      try {
        for (const [resultType, values] of Object.entries(results)) {
          if (Array.isArray(values)) {
            for (const result of values) {
              for (const [key, property] of Object.entries(result)) {
                if (typeof property === 'string') {
                  if (property.toLowerCase().includes(search)) {
                    return true
                  }
                }
              }
            }
          }
        }
      } catch (e) {
        return false
      }
      return false
    },
    emptyResults () {
      const results = this.searchResults
      if (isEmpty(results.cities) &&
        isEmpty(results.homes) &&
        isEmpty(results.regions) &&
        isEmpty(results.communities) &&
        isEmpty(results.apartments) &&
        isEmpty(results.zip_codes)) {
        return true
      }
      return false
    },
    searchIsRegion () {
      if (this.search.toLowerCase() === getInitialSettingRegionName() ||
        (typeof this.searchRegion !== 'undefined' && this.searchRegion !== null)) {
        return true
      } else {
        return false
      }
    },
    searchRegion () {
      const found = this.regions.find(region => {
        return this.search.toLocaleLowerCase() === region.name.toLowerCase() || this.search.toLowerCase() === region.slug
      })
      return found
    },
    stateClass () {
      if (this.searchIsRegion) {
        return 'goToRegionMode'
      } else {
        return 'showResultsMode'
      }
    },
    /**
     * todo(scd): so this is because at this time /regions api is not live
     * @return {*}
     */
    filteredRegions () {
      if (isEmpty(this.regions)) {
        return []
      }
      var regions = this.regions.filter(function (region) {
        const found = this.homes.find(home => {
          return home.region_id === region.id
        })
        return found !== null
      })
      return regions
    },
    region () {
      if (this.homes.length > 0) {
        const home = this.homes[0]
        const region = this.regions.find(region => {
          return home.region_id === region.id
        })
        if (region !== null) {
          return region
        } else {
          return this.currentRegion
        }
      } else {
        return this.currentRegion
      }
    },
    cities () {
      if (isEmpty(this.homes)) {
        return []
      }
      const cities = groupBy(this.homes, function (home) {
        return home.city + ', ' + home.state
      })
      var sortable = []
      for (var key in cities) {
        // if (cities.hasOwnProperty(key)) {
        sortable.push({
          name: key,
          homes: cities[key]
        })
      }
      // } // each item is an array in format [key, value]
      console.log(sortable)
      // sort items by value
      sortable.sort(function (a, b) {
        const x = a.homes.length
        const y = b.homes.length
        return x > y ? -1 : x < y ? 1 : 0
      })
      return sortable
    },
    specialRegion () {
      const currentRegionSlug = this.currentRegionSlug
      const specialMatches = ['toro', 'toron', 'toront', 'toronto', 'toronto, on', 'toronto,on', 'toronto-on']
      if (specialMatches.includes(this.search)) {
        return this.specialRegions.toronto
      }
      if (currentRegionSlug !== '' && typeof this.specialRegions[currentRegionSlug] !== 'undefined') {
        return this.specialRegions[currentRegionSlug]
      } else {
        return null
      }
    },
    isSpecialRegion () {
      if (this.specialRegion !== null) {
        return true
      } else {
        return false
      }
    },
    /**
     * this is critical for use in the search widget when return is pressed and focus is
     * in the text box
     * @returns {{}|boolean}
     */
    firstSearchResult () {
      if (!isEmpty(this.searchResults)) {
        const result = {}
        if (!isEmpty(this.searchResults.regions)) {
          const keys = Object.keys(this.searchResults.regions)
          const region = this.searchResults.regions[keys[0]]
          result.type = 'region'
          result.region = region
          if (region.active_home_count === 0) {
            // it must be the apartments link
            result.hideHomes = true
          }
          return result
        } else if (!isEmpty(this.searchResults.cities)) {
          result.type = 'city'
          result.city = this.searchResults.cities[0]
          return result
        } else if (!isEmpty(this.searchResults.communities)) {
          result.type = 'community'
          const communities = this.searchResults.communities
          const keys = Object.keys(communities)
          result.community = this.searchResults.communities[keys[0]]
          return result
        } else if (!isEmpty(this.searchResults.apartments)) {
          result.type = 'apartment'
          const keys = Object.keys(this.searchResults.apartments)
          result.apartment = this.searchResults.apartments[keys[0]]
          return result
        } else if (!isEmpty(this.searchResults.homes)) {
          result.type = 'home'
          result.home = this.searchResults.homes[0]
          return result
        } else if (!isEmpty(this.searchResults.zip_codes)) {
          result.type = 'zip'
          result.zip = this.searchResults.zip_codes[0]
          return result
        }
      }
      return false
    }

  },
  methods: {
    ...mapActions({
      initRegion: 'initCurrentRegion',
      initRegions: 'initRegions',
      setCurrentRegion: 'setCurrentRegion',
      initFavorites: 'initFavorites'
    }),
    isEmpty,
    iconPath,
    updateSearch (event) {
      this.search = event.target.value
    },
    focusNext (event) {
      if (this.showResults) {
        if (this.isSpecialRegion) {
          this.$refs.searchWidgetResultsEmptySpecialRegion.focusNext(event)
        } else if (this.emptyResults) {
          this.$refs.searchWidgetEmptyResults.focusNext(event)
        } else if (!isEmpty(this.currentRegion)) {
          this.$refs.searchWidgetResults.focusNext(event)
        }
      }
    },
    clearSearch () {
      console.log('clear search')
      this.search = ''
      this.currentRegionSlug = this.defaultRegionSlug
      this.currentRegion = this.defaultRegion
      this.showNoResultsText = false
      this.$refs.searchWidgetInput.focus()
      this.restoreInitialState()
    },
    searchNowFromButton (event) {
      console.log('searchNowFromButton', event)
      debugger
      // // throw 'make error'
      // // return
      if (this.isSpecialRegion) {
        const specialRegion = this.specialRegion
        console.log('searchNowFromButton.isSpecialRegion', specialRegion.path)

        window.location.href = specialRegion.path
      } else if (this.searchIsRegion) {
        const region = this.searchRegion
        console.log('searchNowFromButton.searchIsRegion', '/find/' + region.slug + '/')

        window.location.href = '/find/' + region.slug + '/'
      } else {
        console.log('searchNowFromButton.else')
        if (event instanceof KeyboardEvent && event.key === 'Enter') {
          console.log('searchNowFromButton.else is Enter')
          if (this.search.length >= 3 && !this.searchResultsContainSearch) {
            console.log('searchNowFromButton.search not in results', 'do search in place for "' + this.search + '" immediately')
            this.searchNow(event, true)
            return
          }
          if (this.firstSearchResult) {
            console.log('searchNowFromButton.else.firstSearchResult', this.firstSearchResult)
            this.didClickLink(this.firstSearchResult, event)
            return
          }
        }
        console.log('searchNowFromButton.else.event', event)
        this.searchNow(event, true)
      }
    },
    searchNow (event, deep = false) {
      console.log('searchNow', event)
      this.searchingNow = true
      this.searchString(this.search, deep)
    },
    searchString (string, deep = false) {
      this.loading = true
      searchRegions(string, deep, this.maxResultsDisplay)
        .then(result => {
          console.log('searchString.results', result, result.data, result.data.length)
          if (Array.isArray(result.data) && result.data.length === 0) {
            /**
             * 2023-04-07 (SCD) When the server is returning short circuit results (right now that
             * means it retures `[]` don't blow away the current search results as that is not
             * usually the right thing to do
             */
            console.warn('searchString.results short-circuit', result.data)
          } else {
            this.searchResults = result.data
            this.showNoResultsText = this.emptyResults
          }
          if (this.regions.length > 0) {
            this.currentRegion = this.regions[0]
            this.currentRegionSlug = this.currentRegion.slug
          }
          this.setInitialState(this.search, this.searchResults)
          this.loading = false
        })
        .catch(e => {
          this.loading = false
          console.error('searchString.error', e)
        })
    },
    getLocation (event) {
      console.log('getLocation', event)
      this.$getLocation({
        enableHighAccuracy: true, // defaults to false
        timeout: Infinity, // defaults to Infinity
        maximumAge: 0 // defaults to 0

      })
        .then(coordinates => {
          console.log('location', coordinates)
          window.location.href = `/find?lat=${coordinates.lat}&lng=${coordinates.lng}`
        })
    },
    didClickLink (value, event) {
      console.log('SearchWidget.didClickLink', value.type, value, event)
      this.showResults = false
      if (this.shouldEmit) {
        this.$emit('did-click-link', value)
        return
      }
      const currentUrl = new URL(window.location.href)
      console.log('current', currentUrl)
      const url = new URL(currentUrl.origin)
      let path = ['find']
      let region = null
      switch (value.type) {
        case 'region':
          if (value.region.slug.includes('toronto')) {
            url.pathname = '/region/toronto/'
            window.location.href = url.href
            return
          }
          path.push(value.region.slug)
          if (value.hideHomes) {
            path.push('apartments')
          }
          if (value.hideApartments) {
            path.push('homes')
          }
          console.log('didClickLink.region', path)
          break
        case 'apartment':
          path = ['/apartment/', value.apartment.slug]
          break
        case 'community':
          path = ['/community/', value.community.slug]
          break
        case 'city':
          region = this.getRegion(value.city.region_id)
          if (!isEmpty(region)) {
            path.push(region.slug)
          }
          path.push(slugify(value.city.city.toLowerCase() + '-' + value.city.state.toLowerCase()))
          break
        case 'home':
          path = ['home', value.home.slug]
          break
        case 'zip':
          region = this.getRegion(value.zip.region_id)
          if (!isEmpty(region)) {
            path.push(region.slug)
          }
          url.searchParams.append('zip', value.zip.zip)
          break
        case 'search':
          url.searchParams.append('text_query', value.search)
          break
      }
      url.pathname = path.join('/') + '/'
      window.location.href = url.toString()
    },
    onClickOutside (event) {
      console.log('Clicked outside. Event: ', event)
      this.showResults = false
    },
    inputLostFocus (event) {
      console.log('inputLostFocus', event)
      if (typeof event.relatedTarget !== 'undefined' && event.relatedTarget !== null) {
        const target = event.relatedTarget
        const classesOfInterest = ['regionLink', 'cityLink', 'propertyLink', 'zipLink', 'homeLink', 'locationLink']
        if (target.classList.length > 0 && arrayContainsAny(target.classList, classesOfInterest)) {
          console.log('in classList', event.relatedTarget.classList)
          return
        }
      }
      // have to give enough time for the getLocation to fire since that doesn't seem to pass the $event.relatedTarget for
      // above trick to work
      setTimeout(
        () => {
          this.showResults = false
        }, 500
      )
    },
    inputBlur (event) {
      console.log('inputBlur', event)
    },
    setInitialState (search, results = {}) {
      // only set the initial state once
      console.trace('SearchWidget.setInitialState')
      console.log('SearchWidget.setInitialState', search, results)
      if (this.initialState === null) {
        this.initialState = {
          search: search,
          searchResults: results,
          currentRegion: this.currentRegion,
          slug: this.currentRegionSlug
        }
      }
    },
    restoreInitialState () {
      if (this.initialState === null) {
        this.search = ''
        this.searchResults = {}
      } else {
        this.search = this.initialState.search
        this.searchResults = this.initialState.searchResults
        this.currentRegionSlug = this.initialState.currentRegionSlug
        this.currentRegion = this.initialState.currentRegion
      }
    }
  }
}
</script>
