import pluralize from 'pluralize'

function gcd (a, b) {
  return (b) ? gcd(b, a % b) : a
}

var decimalToFraction = function (_decimal) {
  if (_decimal === parseInt(_decimal)) {
    return {
      top: parseInt(_decimal),
      bottom: 1,
      display: parseInt(_decimal) + '/' + 1
    }
  } else {
    var top = _decimal.toString().includes('.') ? _decimal.toString().replace(/\d+[.]/, '') : 0
    var bottom = Math.pow(10, top.toString().replace('-', '').length)
    if (_decimal >= 1) {
      top = +top + (Math.floor(_decimal) * bottom)
    } else if (_decimal <= -1) {
      top = +top + (Math.ceil(_decimal) * bottom)
    }

    var x = Math.abs(gcd(top, bottom))
    return {
      top: (top / x),
      bottom: (bottom / x),
      display: (top / x) + '/' + (bottom / x)
    }
  }
}

const fractionToString = (decimal, useHtml = true) => {
  const intPortion = Math.floor(decimal)
  const fractionPortion = decimal - intPortion
  if (fractionPortion === 0) {
    return intPortion
  }
  const converted = decimalToFraction(fractionPortion)
  if (useHtml) {
    return intPortion.toString() + ' <sup>' + converted.top.toString() + '</sup>&frasl;<sub>' + converted.bottom.toString() + '</sub>'
  } else {
    return intPortion.toString() + ' ' + converted.top.toString() + '/' + converted.bottom.toString()
  }
}
const uuid = () => {
  return uniqueString()
}

const uniqueString = () => {
  return '_' + Math.random().toString(36).substr(2, 9)
}
const removeCountry = (address) => {
  const parts = address.split(',')
  const countries = ['US', 'USA', 'UNITED STATES', 'CA', 'CANADA']
  if (parts.length > 1) {
    const countryContender = parts[parts.length - 1].toUpperCase().trim()
    if (countries.includes(countryContender)) {
      parts.pop()
      return parts.join(',')
    }
  }
  return address
}
const titleCase = variable => {
  /* To Title Case © 2018 David Gouch | https://github.com/gouch/to-title-case */
  var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i
  var alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/
  var wordSeparators = /([ :–—-])/

  return variable.split(wordSeparators)
    .map((current, index, array) => {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' ||
          (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase()
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current
      }

      /* Ignore URLs */
      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase()
      })
    })
    .join('')
}

const slugify = string => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

const camelCase = (str) => {
  const string = str.trim()
  return string.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase()
  }).replace(/\s+/g, '')
}

const toKebab = (string) => {
  return string
    .split('')
    .map((letter, index) => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`
      }
      return letter
    })
    .join('')
    .trim()
    .replace(/[_\s]+/g, '-')
}

const toCamel = (string) => {
  return toKebab(string)
    .split('-')
    .map((word, index) => {
      if (index === 0) return word
      return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join('')
}

const toTitle = (string) => {
  return toKebab(string)
    .split('-')
    .map(word => {
      return word.slice(0, 1).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

const toSentence = (string) => {
  const interim = toKebab(string)
    .replace(/-/g, ' ')
  return interim.slice(0, 1).toUpperCase() + interim.slice(1)
}

const plural = (string, count = 0, include = false) => {
  return pluralize(string, count, include)
}

const isNumeric = (value) => {
  return !isNaN(parseFloat(value)) && isFinite(value)
}
/**
 * Formats a number as currency using the user's locale. 1234.56 => '$1,234.56'
 *
 * @param  {number} value      The number to be formatted
 * @param {boolean} showDecimal if you want the decimal part shown
 * @param  {string} currency The type of currency, such as 'USD' or 'JPY'
 * @param  {string} locale   The locale for formatting the number, such as 'en-US'. Defaults to navigator.language
 * @return {string}          The number as a string formatted in the locale of the user
 */

const currency = (value, showDecimal = false, currency = 'USD', locale = navigator.language) => {
  if (isNumeric(value)) {
    const fractionDigits = showDecimal ? 2 : 0
    const valueAsNumber = number(value, fractionDigits, fractionDigits)
    return '$' + valueAsNumber
  } else {
    return ''
  }
  // don't actually use currency per request
//   const fractionDigits = showDecimal ? 2 : 0
//   const formatter = new Intl.NumberFormat(locale, {
//     style: 'currency',
//     currency: currency,
//     minimumFractionDigits: fractionDigits
//   })
//   return formatter.format(value)
}

/**
 * Formats a number to a culture code locale. 1234 => '1,234'
 *
 * @param  {number} num    The number to be formatted
 * @param {number} minimumFractionDigits how many decimals
 * @param {number} maximumFractionDigits how many decimals
 * @param  {string} locale Culture-code for formatting the number, such as 'en-US'. Defaults to navigator.language
 * @return {string}        A formatted number based on a culture code
 */
const number = (num,
  minimumFractionDigits = 0,
  maximumFractionDigits = 1,
  locale = navigator.language) => {
  if (!isNumeric(num)) {
    num = 0
  }
  if (typeof num !== 'number') {
    num = parseFloat(num)
  }
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits
  }).format(num)
}

const ordinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

/**
 * Replaces all characters of a string that surpass a length with a new string.
 *
 * @param  {string} str    String of text to be truncated
 * @param  {number} length Amount of characters to allow before truncating. Defaults to 100
 * @param  {string} append Text to append to the end of a truncated string. Defaults to '...'
 * @return {string}        The original string, or a truncated version if it exceed the allowed limit.
 */
const truncate = (str, length = 100, append = '...') => {
  // TODO: dont split last word
  if (str.length > length) {
    return str.substring(0, length - append.length) + append
  } else {
    return str
  }
}

const translateText = (string) => {
  var translateText = {
    Color: 'Colour',
    Behavior: 'Behaviour',
    Neighborhood: 'Neighbourhood',
    Paycheck: 'Paycheque'
  }
  let newString = string
  // if country code in global then
  for (const american in translateText) {
    const canadianBritish = translateText[american]
    newString = newString.replace(new RegExp(american, 'ig'), canadianBritish)
  }
  return newString
}

/**
 * Takes an array and converts to a readable string
 * Example: ['a','b','c'] -> 'a, b, and c'
 *          ['a','b'] -> 'a and b'
 *          ['a'] -> 'a'
 *          [] -> ''
 *
 * @param {Array} array
 * @return {String}
 */
const toList = (array, lastDelimiter = ' and ') => {
  const newArray = array.slice()
  let toList = ''
  if (typeof newArray !== 'undefined' && Array.isArray(newArray)) {
    if (newArray.length === 1) {
      toList = newArray[0]
    } else if (newArray.length === 2) {
      toList = newArray.shift() + lastDelimiter + newArray.shift()
    } else if (newArray.length > 2) {
      const last = newArray.pop()
      toList = newArray.join(', ')
      toList = toList + lastDelimiter + last
    } else {
      return ''
    }
  }
  return toList
}

const toBoolean = (value) => {
  if (typeof value === 'undefined') {
    return false
  }
  if (typeof value === 'boolean') {
    return value
  }
  if (typeof value === 'string') {
    switch (value.toLowerCase()) {
      case 'true':
      case '1':
      case 'on':
      case 'yes':
        return true
      default:
        return false
    }
  }
  if (typeof value === 'number') {
    return value > 0
  }
  return false
}

const removeLeading = (source, characterToRemove) => {
  let result = source
  while (result.charAt(0) === characterToRemove) {
    result = result.substring(1)
  }
  return result
}

const removeNonNumeric = (source) => {
  return source.replace(/\D/g, '')
}

const cleanState = (source) => {
  const result = source.split(',')
  return result[0]
}

export {
  titleCase, slugify, camelCase, plural, isNumeric, currency, translateText,
  toKebab, toTitle, toCamel, toSentence,
  uniqueString, toList, toBoolean,
  uuid, number, ordinal, truncate, removeCountry, removeLeading, fractionToString,
  removeNonNumeric,
  cleanState
}
