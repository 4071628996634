var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"widgetResults",on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.clickRef.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }$event.preventDefault();return _vm.focusNext.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.focusNext.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }$event.preventDefault();return _vm.focusNext.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.focusPrevious.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.preventDefault();return _vm.focusPrevious.apply(null, arguments)}]}},[(_vm.loading)?_c('li',{staticClass:"loading"},[_vm._v("Searching...")]):_vm._e(),_vm._v(" "),(!_vm.isEmpty(_vm.regions))?[_vm._l((_vm.regions),function(region,region_id){return [(!region.slug.includes('toronto') && (region.is_sfh_region ||region.active_home_count > 0))?_c('li',{key:'region_' + region_id,staticClass:"region"},[_c('a',{ref:_vm.regionRefSlug(region),refInFor:true,staticClass:"regionLink",class:{
        closestRegion: _vm.isClosestRegion(region),
        deepResult: _vm.isDeepResult(region),
        hasHomes: region.active_home_count > 0,
        hasApartments: region.active_apartment_count > 0
         },attrs:{"href":("/find/" + (region.slug) + "/"),"title":region.name + ' single-family homes',"tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.clickRegion(region,false, false, $event)}}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(region.name))]),_vm._v(" "),_c('svg',{attrs:{"role":"presentation"}},[_c('use',{attrs:{"xlink:href":_vm.iconPath('search')}})])],1)]):_vm._e(),_vm._v(" "),(region.active_apartment_count > 0)?_c('li',{key:'region_apartment_' + region_id,staticClass:"region"},[_c('a',{ref:_vm.regionApartmentRefSlug(region),refInFor:true,staticClass:"regionLink regionLinkApartments",class:{
        closestRegion: _vm.isClosestRegion(region),
        deepResult: _vm.isDeepResult(region)
         },attrs:{"href":_vm.regionLink(region, true,false),"title":region.name + ' apartments',"tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.clickRegion(region, true, false, $event)}}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(region.name))]),_vm._v(" "),_c('svg',{attrs:{"role":"presentation"}},[_c('use',{attrs:{"xlink:href":_vm.iconPath('search')}})])],1)]):_vm._e()]})]:_vm._e(),_vm._v(" "),(_vm.showCities && _vm.filteredCities.length > 0)?_vm._l((_vm.filteredCities),function(city){return _c('li',{key:'city_' + _vm.slug(city.city + city.state),staticClass:"city"},[_c('a',{ref:_vm.cityRefSlug(city),refInFor:true,staticClass:"cityLink",class:{
             deepResult: _vm.isDeepResult(city)
         },attrs:{"href":_vm.linkForCity(city),"tabindex":"-1"},on:{"click":function($event){return _vm.clickCity(city, $event)}}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(city.city)+", "+_vm._s(city.state))]),_vm._v(" "),_c('svg',{attrs:{"role":"presentation"}},[_c('use',{attrs:{"xlink:href":_vm.iconPath('search')}})])],1)])}):_vm._e(),_vm._v(" "),(_vm.showApartments && !_vm.isEmpty(_vm.apartments))?_vm._l((_vm.apartments),function(apartment){return _c('li',{key:'apartment' + apartment.slug,staticClass:"apartment"},[_c('a',{ref:_vm.apartmentRefSlug(apartment),refInFor:true,staticClass:"apartmentLink",class:{
             deepResult: _vm.isDeepResult(apartment)
         },attrs:{"href":'/apartment/' + apartment.slug + '/',"tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.clickApartment(apartment, $event)}}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(apartment.name))]),_vm._v(" "),(apartment.city !== '')?_c('span',{staticClass:"address"},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(apartment.city)+", "+_vm._s(apartment.state))])],1):_vm._e(),_vm._v(" "),_c('svg',{attrs:{"role":"presentation"}},[_c('use',{attrs:{"xlink:href":_vm.iconPath('apartment')}})])],1)])}):_vm._e(),_vm._v(" "),(_vm.showCommunities && !_vm.isEmpty(_vm.communities))?_vm._l((_vm.communities),function(community){return _c('li',{key:'community' + community.slug,staticClass:"community"},[_c('a',{ref:_vm.communityRefSlug(community),refInFor:true,staticClass:"communityLink",class:{
             deepResult: _vm.isDeepResult(community)
         },attrs:{"href":'/community/' + community.slug + '/',"tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.clickCommunity(community, $event)}}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(community.name))]),_vm._v(" "),(community.city !== '')?_c('span',{staticClass:"address"},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(community.city)+", "+_vm._s(community.state))])],1):_vm._e(),_vm._v(" "),_c('svg',{attrs:{"role":"presentation"}},[_c('use',{attrs:{"xlink:href":_vm.iconPath('location')}})])],1)])}):_vm._e(),_vm._v(" "),(!_vm.isEmpty(_vm.homesInResults))?_vm._l((_vm.homesInResults),function(home,id){return _c('li',{key:'home_' + id,staticClass:"address"},[_c('a',{ref:_vm.homeRefSlug(home),refInFor:true,staticClass:"homeLink",attrs:{"title":home.shortDescription,"tabindex":"-1"},on:{"click":function($event){return _vm.clickHome(home,id, $event)}}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(home.title))]),_vm._v(" "),_c('svg',{attrs:{"role":"presentation"}},[_c('use',{attrs:{"xlink:href":_vm.iconPath('location')}})])],1)])}):_vm._e(),_vm._v(" "),(_vm.showZips && _vm.zipCodes.length > 0)?[_c('li',{staticClass:"section"},[_vm._v("Zip Codes")]),_vm._v(" "),_vm._l((_vm.zipCodes),function(zip){return _c('li',{key:'zip_' + zip.zip,staticClass:"zip"},[_c('a',{ref:_vm.zipRefSlug(zip),refInFor:true,staticClass:"zipLink",class:{
             deepResult: _vm.isDeepResult(zip)
         },attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.clickZip(zip, $event)}}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(zip.zip))]),_vm._v(" "),_c('svg',{attrs:{"role":"presentation"}},[_c('use',{attrs:{"xlink:href":_vm.iconPath('search')}})])],1)])})]:_vm._e(),_vm._v(" "),(_vm.showSearchMore && _vm.search.length >= 3)?_c('li',[_c('a',{staticClass:"searchLink",attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.clickMore(_vm.search, $event)}}},[_vm._v("\n      More Results\n      "),_c('svg',{attrs:{"role":"presentation"}},[_c('use',{attrs:{"xlink:href":_vm.iconPath('search')}})])])]):_vm._e(),_vm._v(" "),(_vm.showMapSearch && !_vm.loading)?_c('li',[_c('a',{ref:"mapSearch",staticClass:"findLink",attrs:{"href":"/find/","tabindex":"-1"}},[_vm._v("\n      Map Search\n      "),_c('svg',{attrs:{"role":"presentation"}},[_c('use',{attrs:{"xlink:href":_vm.iconPath('region')}})])])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }