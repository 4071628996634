<template>
  <ul class="widgetResults"
      @keydown.enter="clickRef"
      @keydown.tab.prevent="focusNext"
      @keydown.down.prevent="focusNext"
      @keydown.right.prevent="focusNext"
      @keydown.up.prevent="focusPrevious"
      @keydown.left.prevent="focusPrevious">
    <li v-if="loading" class="loading">Searching...</li>
    <template v-if="!isEmpty(regions)">
      <template v-for="(region, region_id) of regions">
        <!-- NOTE: if changing the order of these links you also need to update the order
        in mixins.searchWidget computed property linkRefs -->
        <li class="region" v-if="!region.slug.includes('toronto') && (region.is_sfh_region ||region.active_home_count > 0)" :key="'region_' + region_id">
          <a @click.prevent="clickRegion(region,false, false, $event)"
             :href="`/find/${region.slug}/`"
             :title="region.name + ' single-family homes'"
             :class="{
          closestRegion: isClosestRegion(region),
          deepResult: isDeepResult(region),
          hasHomes: region.active_home_count > 0,
          hasApartments: region.active_apartment_count > 0
           }"
             class="regionLink"
             :ref="regionRefSlug(region)"
             tabindex="-1"
          >
            <text-highlight :queries="search">{{ region.name }}</text-highlight>
            <svg role="presentation">
              <use :xlink:href="iconPath('search')"></use>
            </svg>
          </a>
        </li>
        <li class="region" v-if="region.active_apartment_count > 0" :key="'region_apartment_' + region_id">
          <a @click.prevent="clickRegion(region, true, false, $event)"
             :href="regionLink(region, true,false)"
             :title="region.name + ' apartments'"
             :class="{
          closestRegion: isClosestRegion(region),
          deepResult: isDeepResult(region)
           }"
             class="regionLink regionLinkApartments"
             :ref="regionApartmentRefSlug(region)"
             tabindex="-1"
          >
            <text-highlight :queries="search">{{ region.name }}</text-highlight>
            <svg role="presentation">
              <use :xlink:href="iconPath('search')"></use>
            </svg>
          </a>
        </li>
      </template>
    </template>
    <template v-if="showCities && filteredCities.length > 0">
      <li class="city" v-for="(city) of filteredCities" :key="'city_' + slug(city.city + city.state)">
        <a @click="clickCity(city, $event)"
           class="cityLink"
           :class="{
               deepResult: isDeepResult(city)
           }"
           :href="linkForCity(city)"
           :ref="cityRefSlug(city)"
           tabindex="-1"
        >
          <text-highlight :queries="search">{{ city.city }}, {{ city.state }}</text-highlight>
          <svg role="presentation">
            <use :xlink:href="iconPath('search')"></use>
          </svg>
        </a>
      </li>
    </template>
    <template v-if="showApartments && !isEmpty(apartments)">
      <li class="apartment" v-for="(apartment) of apartments" :key="'apartment' + apartment.slug">
        <a @click.prevent="clickApartment(apartment, $event)"
           :href="'/apartment/' + apartment.slug + '/'"
           class="apartmentLink"
           :class="{
               deepResult: isDeepResult(apartment)
           }"
           :ref="apartmentRefSlug(apartment)"
           tabindex="-1"
        >
          <text-highlight :queries="search">{{ apartment.name }}</text-highlight>
          <span v-if="apartment.city !== ''" class="address"> <text-highlight :queries="search">{{
              apartment.city
            }}, {{ apartment.state }}</text-highlight></span>
          <svg role="presentation">
            <use :xlink:href="iconPath('apartment')"></use>
          </svg>
        </a>
      </li>
    </template>
    <template v-if="showCommunities && !isEmpty(communities)">
      <li class="community" v-for="(community) of communities" :key="'community' + community.slug">
        <a @click.prevent="clickCommunity(community, $event)"
           :href="'/community/' + community.slug + '/'"
           class="communityLink"
           :class="{
               deepResult: isDeepResult(community)
           }"
           :ref="communityRefSlug(community)"
           tabindex="-1"
        >
          <text-highlight :queries="search">{{ community.name }}</text-highlight>
          <span v-if="community.city !== ''" class="address"> <text-highlight :queries="search">{{
              community.city
            }}, {{ community.state }}</text-highlight></span>
          <svg role="presentation">
            <use :xlink:href="iconPath('location')"></use>
          </svg>
        </a>
      </li>
    </template>
    <template v-if="!isEmpty(homesInResults)">
      <li class="address" v-for="(home, id) in homesInResults" :key="'home_' + id">
        <a v-on:click="clickHome(home,id, $event)"
           :title="home.shortDescription"
           class="homeLink"
           :ref="homeRefSlug(home)"
           tabindex="-1"
        >
          <text-highlight :queries="search">{{ home.title }}</text-highlight>
          <svg role="presentation">
            <use :xlink:href="iconPath('location')"></use>
          </svg>
        </a>
      </li>
    </template>
    <template v-if="showZips && zipCodes.length > 0">
      <li class="section">Zip Codes</li>
      <li class="zip" v-for="(zip) of zipCodes" :key="'zip_' + zip.zip">
        <a v-on:click="clickZip(zip, $event)"
           class="zipLink"
           :class="{
               deepResult: isDeepResult(zip)
           }"
           :ref="zipRefSlug(zip)"
           tabindex="-1">
          <text-highlight :queries="search">{{ zip.zip }}</text-highlight>
          <svg role="presentation">
            <use :xlink:href="iconPath('search')"></use>
          </svg>
        </a>
      </li>
    </template>
    <li v-if="showSearchMore && search.length >= 3">
      <a v-on:click="clickMore(search, $event)" class="searchLink"
         tabindex="-1">
        More Results
        <svg role="presentation">
          <use :xlink:href="iconPath('search')"></use>
        </svg>
      </a>
    </li>
    <li v-if="showMapSearch && !loading">
      <a href="/find/" class="findLink"
         ref="mapSearch"
         tabindex="-1">
        Map Search
        <svg role="presentation">
          <use :xlink:href="iconPath('region')"></use>
        </svg>
      </a>
    </li>
  </ul>
</template>

<script>
import TextHighlight from 'vue-text-highlight/dist/component' // include component but not style sheet
import { slugify, plural } from '@/utilities/stringFormatters'
import { iconPath } from '@/utilities/urlHelpers'
import { getInitialSettingRegionSlug } from '@/utilities/helpers'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'
import { searchWidgetMixin } from '@/components/mixins/searchWidget'
import { GTAG_EVENT_SEARCH_CLICK, GTAG_PARAM_SEARCH_CLICK, pushGTM } from '@/api/gtag-utils'

export default {
  name: 'SearchWidgetResults',
  mixins: [searchWidgetMixin],
  components: {
    TextHighlight
  },
  props: {
    search: {
      type: String,
      required: true
    },
    searchResults: {
      type: Object,
      required: true
    },
    currentRegion: {
      required: true
    },
    maxResultsDisplay: {
      type: Number,
      default: 5,
      required: false
    },
    showZips: {
      type: Boolean,
      required: false,
      default: false
    },
    showCities: {
      type: Boolean,
      required: false,
      default: false
    },
    showCommunities: {
      type: Boolean,
      required: false,
      default: true
    },
    showApartments: {
      type: Boolean,
      required: false,
      default: true
    },
    showSearchMore: {
      type: Boolean,
      required: false,
      default: false
    },
    showMapSearch: {
      type: Boolean,
      required: false,
      default: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      allRegions: 'regions',
      getRegion: 'region'
    }),
    theRefs () {
      return this.$refs
    },
    initialRegionsSlug () {
      return getInitialSettingRegionSlug()
    },
    regions () {
      if (typeof this.searchResults.regions !== 'undefined') {
        const keys = Object.keys(this.searchResults.regions)
        const regions = this.searchResults.regions
        keys.forEach(key => {
          const region = regions[key]
          console.log('region', region.slug, region)
          region.lat = parseFloat(region.lat)
          region.lng = parseFloat(region.lng)
          regions[key] = region
        })

        return regions
      } else {
        return {}
      }
    },
    regionsFromHomes () {
      const regions = {}
      for (const homeId in this.homesInResults) {
        // noinspection JSUnfilteredForInLoop
        const home = this.homesInResults[homeId]
        regions[home.region_id] = this.allRegions.find(region => {
          return home.region_id === region.id
        })
      }
      return regions
    }
  },
  methods: {
    iconPath,
    plural,
    isEmpty,
    hasApartments (region) {
      const found = []
      for (const apartmentsKey in this.apartments) {
        if (Object.prototype.hasOwnProperty.call(this.apartments, apartmentsKey)) {
          const apartment = this.apartments[apartmentsKey]
          if (apartment.region_id.toString() === region.id.toString()) {
            found.push(apartment)
          }
        }
      }
      if (!isEmpty(found)) {
        return found.length
      } else {
        return false
      }
    },
    isDeepResult (result) {
      if (typeof result.deep !== 'undefined') {
        return result.deep
      }
      return false
    },
    isClosestRegion (region) {
      if (getInitialSettingRegionSlug() === region.slug) {
        return true
      } else {
        return false
      }
    },
    clickRegion (region, hideHomes, hideApartments, event) {
      let path = '/region/' + region.slug + '/'
      if (hideHomes) {
        path = path + '?hideHomes=true'
      } else if (hideApartments) {
        path = path + '?hideApartments=true'
      }
      pushGTM(GTAG_EVENT_SEARCH_CLICK, { [GTAG_PARAM_SEARCH_CLICK]: path })
      this.$emit('did-click-link', {
        type: 'region',
        region: region,
        hideHomes: hideHomes,
        hideApartments: hideApartments
      }, event)
    },
    regionLink (region, hideHomes, hideApartments, event) {
      let path = '/region/' + region.slug + '/'
      if (hideHomes) {
        path = path + '?hideHomes=true'
      } else if (hideApartments) {
        path = path + '?hideApartments=true'
      }
      return path
    },
    clickZip (zip, event) {
      const path = '/find/?zip=' + zip.zip
      pushGTM(GTAG_EVENT_SEARCH_CLICK, { [GTAG_PARAM_SEARCH_CLICK]: path })
      this.$emit('did-click-link', {
        type: 'zip',
        zip: zip
      }, event)
    },
    clickHome (home, id, event) {
      const path = '/home/' + home.slug + '/'
      pushGTM(GTAG_EVENT_SEARCH_CLICK, { [GTAG_PARAM_SEARCH_CLICK]: path })
      home.id = id
      this.$emit('did-click-link', {
        type: 'home',
        home: home
      }, event)
    },
    clickCity (city, event) {
      const path = '/find/' + slugify(city.city + ' ' + city.state)
      pushGTM(GTAG_EVENT_SEARCH_CLICK, { [GTAG_PARAM_SEARCH_CLICK]: path })
      this.$emit('did-click-link', {
        type: 'city',
        city: city
      }, event)
    },
    clickCommunity (community, event) {
      const path = '/community/' + community.slug
      pushGTM(GTAG_EVENT_SEARCH_CLICK, { [GTAG_PARAM_SEARCH_CLICK]: path })
      this.$emit('did-click-link', {
        type: 'community',
        community: community
      }, event)
    },
    clickApartment (apartment, event) {
      const path = '/apartment/' + apartment.slug
      pushGTM(GTAG_EVENT_SEARCH_CLICK, { [GTAG_PARAM_SEARCH_CLICK]: path })
      this.$emit('did-click-link', {
        type: 'apartment',
        apartment: apartment
      }, event)
    },
    clickMore (search, event) {
      this.$emit('did-click-link', {
        type: 'search',
        search: search
      }, event)
    },
    slug (s) {
      return slugify(s)
    },
    address (address) {
      return address.item
    },
    sortGroupedResultsByPropertyCount (a, b) {
      const x = a.length
      const y = b.length
      return x > y ? -1 : x < y ? 1 : 0
    },
    regionFromFiltered (filteredRegion) {
      console.log('regionFromFiltered', this.regions, filteredRegion)
      const regionId = filteredRegion[0]
      return this.regionFromId(regionId)
    },
    regionFromId (id) {
      return this.regions.find(region => {
        return parseInt(region.id) === parseInt(id)
      })
    },
    regionNameFromId (id) {
      return this.regionFromId(id).name
    }
  }
}
</script>

<style scoped>

</style>
