
import { distance } from '@/utilities/mapHelpers'
import { isEmpty, mobileBrowser } from '@/utilities/helpers'

import differenceInDays from 'date-fns/differenceInDays'
import { DATE_FORMAT_TRIPOD, dateFromUTC, dateString } from '@/utilities/dateFormatters'
import { currency, uniqueString } from '@/utilities/stringFormatters'
import parseISO from 'date-fns/parseISO'
import { addMarketingParameters } from '@/utilities/marketing'
import isFuture from 'date-fns/isFuture'
import isToday from 'date-fns/isToday'
import { fullPath } from '@/utilities/urlHelpers'
import { amenitySetForAmenity, iconForAmenity } from '@/utilities/amenityHelpers'

class HomeModel {
  /**
   *
   * @param data
   * @param {array} appreciations
   * @param number availabilitySort
   */

  amenityObjects = null
  #galleryImages = []
  #localizedDistance = 0
  #availabilitySort = 1
  /**
   * this is an object defining the home pricing matrix
   * @type {{}}
   */
  #pricing = {}
  hasSolar = false
  showSolarDisclaimer = false
  /**
   * this is used to indicate if the home is waiting backfill of base display data
   * @type {boolean}
   */
  loading = false
  /**
   * this is just a switch to turn on fake ranges
   * @type {boolean}
   */
  #enableFakePriceRange = false && process.env.NODE_ENV !== 'production'
  #percentFake = 25

  constructor (data) {
    this.origionalData = JSON.parse(JSON.stringify(data))
    Object.assign(this, data)

    // these sometimes come in as strings but we use them as numbers
    this.lat = parseFloat(this.lat)
    this.lng = parseFloat(this.lng)

    if (this.rent === undefined || this.rent === null || this.rent === '') {
      this.rent = 0
    } else {
      this.rent = parseFloat(this.rent)
    }

    // todo(scd) this fallback pattern can be removed at a later date
    this.max_rent = this.max_rent === undefined ? this.rent : parseFloat(this.max_rent)
    this.min_rent = this.min_rent === undefined ? this.rent : parseFloat(this.min_rent)

    // todo(scd) this is the faking of price range logic
    this.setFakeMaxMinRent()

    if (
      this.square_feet === undefined ||
      this.square_feet === null ||
      this.square_feet === ''
    ) {
      this.square_feet = 0
    } else {
      this.square_feet = parseFloat(this.square_feet)
    }
    if (this.baths === undefined || this.baths === null || this.baths === '') {
      this.baths = 0
    } else {
      this.baths = parseFloat(this.baths)
    }
    if (this.beds === undefined || this.beds === null || this.beds === '') {
      this.beds = 0
    } else {
      this.beds = parseFloat(this.beds)
    }
    this.processAmenities(data)

    // set up availabilitySort
    this.setAvailabilitySort()

    if (isEmpty(this.concessions)) {
      if (this.special !== '') {
        const special = {
          heading: this.special,
          disclaimer: '',
          concession_key: uniqueString(),
          startDate: new Date(),
          endDate: new Date(),
          isActive: true
        }
        this.concessions = [special]
      } else {
        this.concessions = []
      }
    }
  }

  processAmenities (data) {
    if (typeof data.amenities !== 'undefined' && data.amenities !== null) {
      let amenities = data.amenities
      this.amenityObjects = null
      if (typeof amenities === 'string') {
        amenities = [amenities]
      }
      // some homes return an object not an array, and we only really care about strings
      if (!Array.isArray(amenities)) {
        amenities = Object.values(amenities)
      }
      // remove any empty amenities
      this.amenities = amenities.filter((amenity) => {
        return amenity.trim().length > 0
      })
    } else {
      this.amenities = []
    }
  }

  processAmenityObjects () {
    let id = 0
    const usedIcons = []
    if (typeof this.amenities !== 'undefined' && this.amenities !== null) {
      this.amenityObjects = this.amenities.map((amenity) => {
        const icon = iconForAmenity(amenity)
        const amenitySet = amenitySetForAmenity(amenity)
        id = id + 1
        if (icon !== null && !usedIcons.includes(icon)) {
          usedIcons.push(icon)
          if (isEmpty(amenitySet.name)) {
            amenitySet.name = amenity
          }
          if (isEmpty(amenitySet.id)) {
            amenitySet.id = id
          }
          return amenitySet
        } else {
          return {
            id: id,
            name: amenity,
            icon: null
          }
        }
      })
    } else {
      this.amenityObjects = []
    }
  }

  hasAmenities (amenitiesToCheck) {
    if (this.amenityObjects === null) {
      this.processAmenityObjects()
    }
    console.log('HomeModel.hasAmenities', amenitiesToCheck, this.amenityObjects)
    return amenitiesToCheck.every((amenity) => {
      return this.amenityObjects.some((amenityObject) => {
        if (amenityObject.name === amenity) {
          return true
        }
        if ('filterLabel' in amenityObject && amenityObject.filterLabel === amenity) {
          return true
        }
        return 'amenities' in amenityObject && amenityObject.amenities.includes(amenity)
      })
    })
  }

  /**
   * used to update the home with new data like images from an incoming object
   * @param homeObject
   */
  updateHome (homeObject) {
    if ('address' in homeObject) {
      this.address = homeObject.address
    }
    if ('city' in homeObject) {
      this.city = homeObject.city
    }
    if ('state' in homeObject) {
      this.state = homeObject.state
    }
    if ('zip' in homeObject) {
      this.zip = homeObject.zip
    }
    if ('special' in homeObject) {
      this.special = homeObject.special
    }
    if ('images' in homeObject && Array.isArray(homeObject.images)) {
      this.images = homeObject.images
    }
    if ('open_houses' in homeObject && Array.isArray(homeObject.open_houses)) {
      this.open_houses = homeObject.open_houses
    }
    if ('floorplans' in homeObject && Array.isArray(homeObject.floorplans)) {
      this.floorplans = homeObject.floorplans
    }
    if ('virtual_tour_url' in homeObject) {
      this.virtual_tour_url = homeObject.virtual_tour_url
    }
    if ('days_on_market' in homeObject) {
      this.days_on_market = parseInt(homeObject.days_on_market)
    }
    this.processAmenities(homeObject)

    if (isEmpty(homeObject.concessions)) {
      if (homeObject.special !== '') {
        this.special = homeObject.special
        const special = {
          heading: homeObject.special,
          disclaimer: '',
          concession_key: uniqueString(),
          startDate: new Date(),
          endDate: new Date(),
          isActive: true
        }
        this.concessions = [special]
      } else {
        this.concessions = []
      }
    } else {
      this.concessions = homeObject.concessions
    }
    this.loading = false
  }

  /**
   * @deprecated use availability instead
   * @returns {string}
   */
  // eslint-disable-next-line camelcase
  get when_available () {
    if (process.env.NODE_ENV !== 'production') {
      throw new Error('when_available is deprecated')
    }
    console.error('Deprecated use HomeModel.availability instead')
  }

  /**
   * creates an artificial sorting order that forces featured to the top and then
   * available homes by days on market, then based on the date it will become available
   */
  setAvailabilitySort () {
    if (this.featured) {
      this.#availabilitySort = -10000 // no home is on market this many days
    } else if (!this.pre_marketed) {
      this.#availabilitySort = 0
    } else if (!isEmpty(this.availability)) {
      if (!isEmpty(this.availability.date)) {
        const theDate = parseISO(this.availability.date)
        this.#availabilitySort = differenceInDays(theDate, new Date())
      } else {
        this.#availabilitySort = 98
      }
    } else {
      this.#availabilitySort = 99
    }
  }

  /**
   * creates an artificial sorting order that forces featured to the top and then
   * available homes by days on market, then based on the date it will become available
   * 2023-01-20 (SCD) updated to reflect days on market CU-860pgx1ae
   */
  setAvailabilitySortIncludeDaysOnMarket () {
    if (this.featured) {
      this.#availabilitySort = -10000 // no home is on market this many days
    } else if (!this.pre_marketed) {
      this.#availabilitySort = 0 - this.days_on_market
    } else if (!isEmpty(this.availability)) {
      if (!isEmpty(this.availability.date)) {
        const theDate = parseISO(this.availability.date)
        this.#availabilitySort = differenceInDays(theDate, new Date())
      } else {
        this.#availabilitySort = 98
      }
    } else {
      this.#availabilitySort = 99
    }
  }

  get availabilitySort () {
    return this.#availabilitySort
  }

  setFakeMaxMinRent () {
    if (this.#enableFakePriceRange) {
      const enableFake = Math.random() * 100 <= this.#percentFake
      if (enableFake && this.max_rent === this.min_rent) {
        this.max_rent = this.rent + Math.ceil(this.rent * 0.2)
        this.min_rent = this.rent - Math.ceil(this.rent * 0.2)
      }
    }
  }

  get className () {
    return 'HomeModel'
  }

  get center () {
    return {
      lat: this.lat,
      lng: this.lng
    }
  }

  get name () {
    return this.displayAddress
  }

  get hasImages () {
    return !isEmpty(this.images) && this.images.length > 0
  }

  get imageCount () {
    if (this.hasImages) {
      return this.images.length
    }
    return 0
  }

  get featuredImage () {
    if (this.hasImages) {
      return this.images[0]
    } else {
      return null
    }
  }

  get path () {
    return '/home/' + this.slug
  }

  get comingSoon () {
    return this.pre_marketed
  }

  get isAvailable () {
    return !this.pre_marketed
  }

  get inCommunity () {
    return !isEmpty(this.community) && typeof this.community === 'object'
  }

  get hasAvailable () {
    return !isEmpty(this.availability) && !isEmpty(this.available)
  }

  get hasTour () {
    // return true
    return this.has_self_tour || this.has_agent_tour || this.has_open_house
  }

  get showTourButton () {
    // this seems to always be true since they can always request a showing
    return true
  }

  /**
   * based on type of tours available returns the appropriate labels
   * mainly used in the ui for buttons and links to open forms CU-8685rr12z
   * @return {boolean|string}
   */
  get tourLabel () {
    if (this.has_self_tour) {
      return 'Self-Tour'
    } else if (this.has_agent_tour) {
      return 'Tour'
    } else {
      return 'Tour'
    }
  }

  get tourClass () {
    if (this.has_self_tour) {
      return 'hasSelfTour'
    } else if (this.has_agent_tour) {
      return 'hasAgentTour'
    } else {
      return 'hasScheduleTour'
    }
  }

  /**
   * this is a switch to enable the pre-lease guarantee page and text changes pending TriPOD changes
   * @see https://app.clickup.com/t/8688ej6hp
   * @return {boolean}
   */
  get preLeaseGuaranteeEnabled () {
    return false
  }

  /*
    * returns the url for the pre-lease guarantee page
   */
  get preLeaseGuaranteeUrl () {
    return fullPath('/tricon-pre-lease-guarantee/')
  }

  /**
   * place link title is `View Apple Maps` on iOS and  `View Google Map` on others
   * @return {string}
   */
  get placeLinkTitle () {
    if (mobileBrowser.iOS()) {
      return 'View Apple Map'
    } else {
      return 'View Google Map'
    }
  }

  /**
   * on ios it is a link that trys to open address in apple maps others is a google maps place url with
   * street address
   * @return {string}
   */
  get placeUrl () {
    if (mobileBrowser.iOS()) {
      const url = new URL('https://maps.apple.com')
      url.searchParams.append('address', `${this.address},${this.city},${this.state}`)
      return url.href
    } else {
      const url = new URL('https://www.google.com')
      url.pathname = 'maps/place/' + `${this.address},${this.city},${this.state}`
      return url.href
    }
  }

  get available () {
    const availability = this.availability
    if (availability.display === 'Coming Soon' && !isEmpty(availability.date)) {
      const theDate = parseISO(availability.date)
      return dateString(theDate, 'MMM d, y')
    } else {
      return availability.display
    }
  }

  get displayAddress () {
    return this.address + ', ' + this.city + ', ' + this.state + ' ' + this.zip
  }

  get hasSolarAmount () {
    if (typeof this.solar_amount !== 'undefined' && this.solar_amount > 0) {
      return true
    }
    if (!isEmpty(this.solar_description)) {
      return true
    }
    return false
  }

  get rentRange () {
    if (this.min_rent === this.max_rent) {
      return currency(this.min_rent)
    } else {
      return currency(this.min_rent) + '-' + currency(this.max_rent)
    }
  }

  get isRentRange () {
    return this.min_rent !== this.max_rent
  }

  /**
   * returns the images with validTypes except for the first one, which is featured image
   * @return {*[]|*}
   */
  get galleryImages () {
    if (this.#galleryImages.length === 0 && this.images.length > 0) {
      this.#galleryImages = this.images
        .filter((image) => {
          const validTypes = ['gallery', 'im_gallery', 'rendering']
          if (typeof image.type === 'undefined' || validTypes.includes(image.type)) {
            return true
          }
        })
        .slice(1)
    }
    return this.#galleryImages
  }

  set localizedDistance (point) {
    const lat = point.lat
    const lng = point.lng
    this.#localizedDistance = distance(this.lat, this.lng, lat, lng)
  }

  get localizedDistance () {
    return this.#localizedDistance
  }

  rentInRange (max, min) {
    return this.rent >= min && this.rent <= max
  }

  get hasConcessions () {
    if (!isEmpty(this.concessions)) {
      return true
    } else if (this.specials !== '') {
      return true
    } else if (this.special !== '') {
      return true
    } else {
      return false
    }
  }

  get hasSpecial () {
    return this.special !== ''
  }

  get concession () {
    if (!isEmpty(this.concessions)) {
      return this.concessions[0]
    } else if (this.specials !== '') {
      const special = {
        heading: this.special,
        disclaimer: '',
        concession_key: uniqueString(),
        startDate: new Date(),
        endDate: new Date(),
        isActive: true
      }
      return special
    } else {
      return null
    }
  }

  /**
   * returns a url with marketing parameters populated and optional movinDate and lease terms
   * @param movinDate (optional)
   * @param leaseTerm (optional)
   * @returns {string}
   */
  applyUrl (movinDate = null, leaseTerm = null) {
    const url = new URL(this.apply_url)
    if (movinDate !== null) {
      url.searchParams.set('desiredMoveInDate', dateString(movinDate, DATE_FORMAT_TRIPOD))
    }
    if (leaseTerm !== null) {
      url.searchParams.set('desiredLeaseTerm', leaseTerm)
    }
    return addMarketingParameters(url.href)
  }

  get pricingUrl () {
    if (typeof this.pricing_matrix_url !== 'undefined') {
      return this.pricing_matrix_url
    } else if (typeof this.pricing_url !== 'undefined') {
      /**
       *  2022-08-08 (SCD) as of this time the url should be constructed serverside as pricing_matrix_url this code should be
       *  removed later
       * @type {string}
       */
      return this.pricing_url
    } else {
      /**
       *  2022-08-08 (SCD) as of this time the url should be constructed serverside this code should be
       *  removed later
       * @type {string}
       */
      // construct
      const triconRoot = process.env.MIX_TRICON_SERVER + 'property/pricing'
      const devRoot =
        'https://6fx93stas0.execute-api.us-west-2.amazonaws.com/f-development/property/pricing'

      const proxyRoot = process.env.MIX_API_SERVER + 'property/pricing'
      const currentUrl = new URL(window.location.href)
      let serverPath = triconRoot
      if (currentUrl.searchParams.has('useLive')) {
        serverPath = triconRoot
      } else if (currentUrl.searchParams.has('useDev')) {
        serverPath = devRoot
      } else if (currentUrl.searchParams.has('useProxy')) {
        serverPath = proxyRoot
      }
      const url = new URL(serverPath)
      url.searchParams.set('yardiKey', this.unit_code)
      return url.href
    }
  }

  set pricing (data) {
    if (typeof data === 'object') {
      console.log('data', data)
      const pricing = {
        bestLease: {
          date: dateFromUTC(data.BestLeaseTermDate),
          rent: data.BestLeaseTermRent,
          term: {}
        },
        leaseTerms: {},
        dayTermOptions: data.DayTermOptions.map((option) => {
          return {
            date: dateFromUTC(option.Date),
            terms: option.Terms.map((term) => {
              return 'term_' + term
            })
          }
        }).filter((day) => {
          return isFuture(day.date) || isToday(day.date)
        })
      }
      data.LeaseTerms.forEach((term) => {
        const mappedTerm = {
          id: term.LeaseTerm,
          months: term.LeaseTerm,
          dates: term.Dates.map((date) => {
            return {
              bestRate: date.BestRate,
              date: dateFromUTC(date.Date),
              rent: date.Rent
            }
          }).filter((day) => {
            return isFuture(day.date) || isToday(day.date)
          })
        }
        pricing.leaseTerms['term_' + term.LeaseTerm] = mappedTerm
        if (term.LeaseTerm === data.BestLeaseTerm) {
          pricing.bestLease.term = mappedTerm
        }
      })
      console.log('setPricing', pricing)
      this.#pricing = pricing
    }
  }

  get pricing () {
    return this.#pricing
  }

  /**
   * not all single-family homes have pricing information (server side assumption is max_rent != min_rent so it will
   * construct a pricing_matrix_url, although this may not actually return clean data and in this case the ui should
   * just send to this.applyUrl on a redirect or show error and button
   * @returns {boolean}
   */
  get enablePricing () {
    if (typeof this.pricing_matrix_url === 'undefined' || isEmpty(this.pricing_matrix_url)) {
      return false
    }
    return true
  }

  get hasPricing () {
    return !isEmpty(this.pricing)
  }

  get sqft () {
    return typeof this.square_feet !== 'undefined' ? this.square_feet : 0
  }

  data () {
    return Object.keys(this.origionalData).reduce((data, attribute) => {
      data[attribute] = this[attribute]
      return data
    }, {})
  }
}

export default HomeModel
