<template>

  <div id="vue-search-widget">
    <search-widget v-bind="searchWidget.portal_data"/>

    <template v-if="!isEmpty(portals.KeepMeInformed)">
      <template v-for="keepMeInformed of portals.KeepMeInformed">
        <MountingPortal :mountTo="'#' + keepMeInformed.portal_id" name="source" :key="keepMeInformed.portal_id">
          <keep-me-informed v-bind="keepMeInformed.portal_data"/>
        </MountingPortal>
      </template>
    </template>
    <template v-if="!isEmpty(portals.SearchWidget)">
      <template v-for="searchWidget of portals.SearchWidget">
        <MountingPortal v-if="portalExistsInPage(searchWidget.portal_id)" :mountTo="'#' + searchWidget.portal_id" name="source" :key="searchWidget.portal_id">
          <search-widget v-bind="searchWidget.portal_data"/>
        </MountingPortal>
      </template>
    </template>
  </div>
</template>

<script>

import { initialSettings, isEmpty } from '@/utilities/helpers'
import { mapActions } from 'vuex'
import SearchWidget from '@/components/search_widget/SearchWidget'
// import SearchWidget from '@/components/search_widget/SearchWidget'
export default {
  name: 'Home',
  components: {
    SearchWidget,
    KeepMeInformed: () => import(/* webpackChunkName: "KeepMeInformed" */ '@/components/keep_me_informed/KeepMeInformed.vue')
  },
  data () {
    return {
      is: null,
      portals: {}
    }
  },
  directives: {
    startWithHtml: {
      inserted (el, binding) {
        el.insertAdjacentHTML('afterbegin', binding.value)
      }
    }
  },
  created () {
    console.log('Home.created', this.portals)

    const initSetting = initialSettings()
    this.portals = JSON.parse(initSetting.portals)
    for (const portalsKey in this.portals) {
      const portalType = this.portals[portalsKey]
      console.log('portals', portalsKey, portalType)
      portalType.forEach(portal => {
        const portalData = portal.portal_data
        portal.slots = { }
        if (!isEmpty(portalData['slot-ids'])) {
          const slotIds = portalData['slot-ids']
          for (const slotName in slotIds) {
            const slotId = slotIds[slotName]
            const slot = document.getElementById(slotId)
            portal.slots[slotName] = slot.innerHTML
          }
        }
      })
    }
    this.createDialogMountPortal()
    console.log('Home.created', this.portals)
    this.initRecents()
    this.initFavorites()
    this.initRegions()
  },
  computed: {
    searchWidget () {
      return this.portals.SearchWidget.find(widget => {
        return widget.portal_id === 'vue-search-widget-portal'
      })
    }
  },
  methods: {
    ...mapActions({
      initRegions: 'initRegions',
      initRecents: 'initRecents',
      initFavorites: 'initFavorites'
    }),
    portalExistsInPage (id) {
      const portalElement = document.getElementById(id)
      console.log('App.portalExistsInPage', id, portalElement !== null, portalElement)
      console.trace('App.portalExistsInPage')
      return portalElement !== null
    },
    createDialogMountPortal () {
      const portal = document.createElement('div')
      portal.id = 'dialog_mount_portal'
      const parent = document.getElementById('loadVue').parentNode
      parent.appendChild(portal)
    },
    isEmpty
  }
}
</script>

<style >

</style>
